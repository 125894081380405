import {TweenMax} from "gsap";
import {TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;

class Top {
  constructor() {
    this.init();
  }
  init() {
    
    //kvアニメーション
    const kvItem = new TimelineMax();
    kvItem.from('.p-top__kvItem--01',0.6, {
      opacity: '0',
      scale: '0.8'
    }).from('.p-top__kvItem--02',0.5, {
      opacity: '0',
      y: '10',
    }).staggerFrom('.gsap-order',0.5, {
      opacity: '0',
      scale: '0.8'
    },0.15).from('.p-top__kvSns',0.5, {
      opacity: '0',
      y: '10',
    }).from('.p-top__kvBtn',0.5, {
      opacity: '0',
      y: '10',
    },'-=0.5')
    new ScrollMagic.Scene({
      triggerElement: '.l-main',
      triggerHook: 'onCenter',
    })
    .setTween(kvItem)
//    .addIndicators({ name: "kvItem" })
    .addTo(controller);
    
    //コンテンツ内アニメーション
    var triggerElements = document.querySelectorAll('.fade-in');
    triggerElements.forEach(function (element) {
      var scene = new ScrollMagic.Scene({
        triggerElement: element,
        triggerHook: 0.8,
        reverse: false,
      })
      .setClassToggle(element, 'active')
//      .addIndicators({ name: "anim" })
      .addTo(controller);
    });
    
    // 応募ボタン
    window.addEventListener('scroll', function() {
      var scrollPosition = window.scrollY || window.pageYOffset;
      var footerFixed = document.querySelector('.l-footer__fixedBtn');
      if (scrollPosition > 1000) {
        footerFixed.classList.add('is-active');
      } else {
        footerFixed.classList.remove('is-active');
      }
    });
    
    const fixedBtn = new TimelineMax();
    new ScrollMagic.Scene({
      triggerElement: '.p-top__contact',
      triggerHook: 'onEnter',
    })
    .setTween(fixedBtn)
    .setClassToggle('.l-footer__fixedBtn', 'is-none')
//    .addIndicators({ name: "fixedBtn" })
    .addTo(controller);
    
    //kvSnsBtn
    $('.js-snsBtn').on('click', function () {
      $(this).next().slideToggle(300);
      $(this).toggleClass('is-active');
      $('body').toggleClass('is-active');
      $('.p-top__kvSns').toggleClass('is-active');
    })
    
    //2023チャレンジ
    $('.js-toggleBtn').on('click', function () {
      $(this).next().slideToggle(500)
      $(this).children('span').toggleClass('is-active');
    })
    
    //2023チャレンジ
    $('.js-open').on('click', function () {
      $('.p-top__challengerInner').slideDown(500)
    })
    
    //よくあるご質問
    $('.js-qa-toggle').on('click', function () {
      $(this).next().slideToggle(300)
      $(this).children('span').toggleClass('is-active');
    })

    //youtube
    var options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };
    function handleIntersection(entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          var iframe = entry.target;
          var iframeSrc = iframe.getAttribute('data-src');
          iframe.setAttribute('src', iframeSrc);
          observer.unobserve(iframe);
        }
      });
    }
    var observer = new IntersectionObserver(handleIntersection, options);
    var iframes = document.querySelectorAll('.youtube');
    iframes.forEach(function (iframe) {
      observer.observe(iframe);
    });


    //コンテンツ内アニメーション
//    const elements = document.querySelectorAll('.fade-in');
//    const intersectionObserver = new IntersectionObserver(entries => {
//      entries.forEach(entry => {
//        if (entry.isIntersecting) {
//          entry.target.classList.add('active');
//          intersectionObserver.unobserve(entry.target);
//        }
//      });
//    });
//    window.addEventListener('scroll', () => {
//      elements.forEach(element => {
//        const elementRect = element.getBoundingClientRect();
//        const windowHeight = window.innerHeight;
//        const isElementInViewport = elementRect.top <= windowHeight / 1.3 && elementRect.bottom >= windowHeight / 1.3;
//        if (isElementInViewport) {
//          element.classList.add('active');
//          intersectionObserver.unobserve(element);
//        }
//      });
//    });

  }
}

export default Top;